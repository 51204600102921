// GSAP IMPORTS

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// FULL SCREEN OVERLAY NAVIGATION

document.addEventListener("DOMContentLoaded", () => {
  const navToggle = document.getElementById("nav-toggle");
  const overlay = document.getElementById("overlay");
  const closeBtn = document.getElementById("close-btn");
  const navLinks = document.querySelectorAll("#overlay nav a");

  let overlayOpen = false;

  const openOverlay = () => {
    gsap.to(overlay, {duration: 1, opacity: 1, visibility: "visible", ease: "power3.inOut"});
    overlayOpen = true;
  };

  const closeOverlay = () => {
    gsap.to(overlay, {duration: 1, opacity: 0, visibility: "hidden", ease: "power3.inOut"});
    overlayOpen = false;
  };

  // Toggle overlay with button click
  navToggle.addEventListener("click", () => { 
    overlayOpen ? closeOverlay() : openOverlay();
  });

  // Close overlay with close button click
  closeBtn.addEventListener("click", () => {
    closeOverlay();
  });

  // Close overlay when clicking outside the nav links
  overlay.addEventListener("click", (event) => {
    if (event.target === overlay) {
      closeOverlay();
    }
  });

  // Close overlay when clicking on any of the nav links
  navLinks.forEach((link) => {
    link.addEventListener("click", () => {
      closeOverlay();
    });
  });

  // Close overlay with Esc key
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape" && overlayOpen) {
      closeOverlay();
    }
  });
});

// SLIDESHOW

const slideDuration = 7; // How long each image stays visible
const fadeDuration = 1;  // How long each image takes to fade in and out

// Create a GSAP timeline for the slideshow
const tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });

// Slide 1 (Hero1)
tl.to(".loadbar", { width: "100%", duration: slideDuration, ease: "none" }, "<") // Animate loadbar
  .to(".hero1", { autoAlpha: 0, duration: fadeDuration }, ">") // Fade out Hero1 after loadbar completes
  .to(".loadbar", { width: "0%", duration: 0 }, "<") // Reset loadbar immediately after Hero1 fades out

// Slide 2 (Hero2)
tl.to(".hero2", { autoAlpha: 1, duration: fadeDuration }) // Fade in Hero2
  .to(".loadbar", { width: "100%", duration: slideDuration, ease: "none" }, "<") // Animate loadbar
  .to(".hero2", { autoAlpha: 0, duration: fadeDuration }, ">") // Fade out Hero2 after loadbar completes
  .to(".loadbar", { width: "0%", duration: 0 }, "<") // Reset loadbar immediately after Hero2 fades out

// Slide 3 (Hero3)
tl.to(".hero3", { autoAlpha: 1, duration: fadeDuration }) // Fade in Hero3
  .to(".loadbar", { width: "100%", duration: slideDuration, ease: "none" }, "<") // Animate loadbar
  .to(".hero3", { autoAlpha: 0, duration: fadeDuration }, ">") // Fade out Hero3 after loadbar completes
  .to(".loadbar", { width: "0%", duration: 0 }, "<") // Reset loadbar immediately after Hero3 fades out
  .to(".hero1", { autoAlpha: 1, duration: fadeDuration }, "<") // Fade in Hero1 again

// Add slideshow controls
document.addEventListener("DOMContentLoaded", () => {
  const pauseSlide = document.getElementById("pauseSlide");
  const nextSlide = document.getElementById("nextSlide");
  let isPaused = false;

  // Pause button controls
  pauseSlide.addEventListener("click", () => {
    if (isPaused) {
      tl.play();
      isPaused = false;
      pauseSlide.querySelector('i').classList.remove('la-play');
      pauseSlide.querySelector('i').classList.add('la-pause');
    } else {
      tl.pause();
      isPaused = true;
      pauseSlide.querySelector('i').classList.remove('la-pause');
      pauseSlide.querySelector('i').classList.add('la-play');
    }
  });

  // Next slide button
  nextSlide.addEventListener("click", () => {
    tl.progress(tl.progress() + 0.33);
    if (!isPaused) tl.play();
  });
});

// ANIMATIONS

gsap.fromTo(".hero-fade", {autoAlpha:0}, {scrollTrigger: "#hero", autoAlpha:1, delay:.75, stagger:.5});
gsap.fromTo(".services-fade", {autoAlpha:0}, {scrollTrigger: "#services", autoAlpha:1, delay:.75, stagger:.5});
gsap.fromTo(".thoughts-fade", {autoAlpha:0}, {scrollTrigger: "#thoughts", autoAlpha:1, delay:.75, stagger:.5});
gsap.fromTo(".about-fade", {autoAlpha:0}, {scrollTrigger: "#about", autoAlpha:1, delay:.75, stagger:.5});
gsap.fromTo(".contact-fade", {autoAlpha:0}, {scrollTrigger: "#contact", autoAlpha:1, delay:.75, stagger:.5});
